import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import {SURVEY_ENGINE_API_HTTP} from '@/modules/constants'

class FormSetService {
    constructor () {
        this.url = `${SURVEY_ENGINE_API_HTTP}/forms`
    }

    async getFormSetById(formSetId, tenantId, parentTenantId) {
        let formSet = null
        // const url = !tenantId ? `${this.url}/by-id/${formSetId}` : `${this.url}/${formSetId}?tenantId=${tenantId}`

        try {
            const { data } = await axios.get(`${this.url}/${formSetId}?tenantId=${tenantId}&parentTenantId=${parentTenantId}`)
            formSet = data
        } catch (e) {
            console.log('error from call', e)
        }
        return formSet
    }

    async getFeedbackFormSet(tenantId) {
        let formSet = null
        try {
            const { data } = await axios.get(`${this.url}/kiosk/feedback?tenantId=${tenantId}`)
            formSet = { ...camelcaseKeys(data, { deep: true }) }
        } catch (e) {
            console.log('error from call', e)
        }
        return formSet
    }

    async getFeedbackFormSetId(tenantId) {
        let formSet = null
        try {
            const { data } = await axios.get(`${this.url}/kiosk/feedback/id?tenantId=${tenantId}`)
            formSet = { ...camelcaseKeys(data, { deep: true }) }
        } catch (e) {
            console.log('error from call', e)
        }
        return formSet?.formSetId
    }
}

export const formSetService = new FormSetService()
